$htmlFontSize: 62.5%;

$fontSizes: (
	base: (
		font-size: pxToRem(16),
		line-height: 1.6
	),
	baseBigger: (
		font-size: pxToRem(20),
		line-height: 1.6
	),
	title: (
		font-size: pxToRem(22),
		line-height: 1.6,
		font-weight: bold
	),
	titleBigger: (
		font-size: pxToRem(26),
		line-height: 1.6
	),
	subtitle: (
		font-size: pxToRem(24),
		line-height: 1.6,
		font-weight: bold
	),
	subtitleBigger: (
		font-size: pxToRem(22),
		line-height: 1.6
	),
	category: (
		font-size: pxToRem(14),
		line-height: 1.6,
		text-transform: uppercase
	),
	quote: (
		font-size: pxToRem(22),
		line-height: 1.6,
		font-weight: normal
	),
	caption: (
		font-size: pxToRem(12),
		line-height: 1.6
	),
	fieldDescription: (
		font-size: pxToRem(16),
		line-height: 1.6
	),
	fieldError: (
		font-size: pxToRem(16),
		line-height: 1.6
	),
	googleMapPopup: (
		font-size: pxToRem(16),
		line-height: 1.6
	)
);
