.mainMenu {
	@include fadeOut($duration4);
	background-color: $colorBgModal;
	display: none;
	min-height: 100vh;
	left: 0;
	position: relative;
	right: 0;
	top: 0;
	z-index: 10;

	&.js-fixed {
		position: fixed;
	}

	&.js-beforeActive {
		display: block;
		will-change: opacity, visibility;
	}

	&.js-duringActive {
		@include fadeIn();
	}
}
