.button {
	@include button();
	@include font(base);
	border-color: currentColor;
	border-style: solid;
	border-width: $buttonBorderSize;
	padding: $inputPadding;
	text-align: center;

	&:disabled,
	&.js-disabled {
		cursor: default;
		opacity: $opacityInputDisabled;
		pointer-events: none;
	}

	&--arrow {
		border: none;
		outline: none;
		// padding: 0;
		& + & {
			// margin-left: $base2;
			padding: 0;
			padding-left: 8px;
		}

		svg {

			height: 21px;
			width: 39px;
		}
	}

	&--slideshowTrigger {
		border: 0;
		background-color: $colorBase;
		border-radius: 50%;
		font-size: 0;
		height: $base * 1.5;
		line-height: 0;
		opacity: 0.5;
		padding: 0;
		text-indent: -10000px;
		width: $base * 1.5;

		&.js-current {
			opacity: 1;
		}
	}

	&--link {
		@include app-link();
		// include here link specific style
		background-color: transparent;
		border-color: transparent;
		padding: 0;
		min-width: 0;
		text-align: left;
	}

	&--prominent {
		@include media($columnsBreakpoint) {
			min-width: ($buttonMinWidth * 2 + $base2);
		}
	}

	&--seamless {
		// include here link specific style
		background-color: transparent;
		border-color: transparent;
		padding-left: 0;
		padding-right: 0;
		min-width: 0;
	}

	&--secondary {
		background-color: transparent;
	}

	&--trackersAcceptAll,
	&--trackersRejectAll {
		width: staticColumnSize(1, 2, $gutter);

		.trackers.js-confirmMode & {
			display: none;
		}
	}

	&--trackersOptions {
		text-align: center;
		width: staticColumnSize(1, 1, $gutter);
	}

	&--trackersSave {
		width: staticColumnSize(1, 1, $gutter);
	}

	&--trackersOk {
		width: staticColumnSize(1, 1, $gutter);

		.trackers:not(.js-confirmMode) & {
			display: none;
		}
	}

	.booking & {
		min-width: 0;
		width: 100%;
	}

	// buttons inside forms
	.form & {
		margin-bottom: $base2;
		margin-left: 0;
		margin-top: $base2;
		min-width: $buttonMinWidth;
		padding: $inputPadding;
	}

	.form > &:first-child {
		margin-top: 0;
	}

	.form__buttons > & {
		margin-left: $base2;
		margin-top: 0;
	}

	.field--search .field__input--text + & {
		flex-grow: 0;
		flex-shrink: 1;
		margin-bottom: 0;
		margin-left: $base2;
		margin-top: 0;
		min-width: 0;

		@include media($columnsBreakpoint) {
			min-width: $buttonMinWidth;
		}
	}


	.field__fields--grid & {
		width: auto;

		@include media($columnsBreakpoint) {
			align-self: flex-end;
			min-width: none;
			width: dynamicColumnSize(1, 3, $gutter);
		}
	}


	&__label {
		.button--trackersDetails.js-toggled &--show,
		.button--trackersDetails:not(.js-toggled) &--hide,
		.trackers.js-expanded .button--trackersOptions &--show,
		.trackers:not(.js-expanded) .button--trackersOptions &--hide {
			display: none;
		}
	}
}
