@use "sass:math";

.image {
	max-width: 100%;

	&--bg {
		height: 100%;
		user-select: none;
		width: 100%;

		.block__figure--video & {
			@include ease(opacity visibility, $duration2);
		}

		.block__figure--video.js-started & {
			@include fadeOut();
		}
	}

	.preview & {
		.block--colSpan12 & {
			@include media($columnsBreakpoint) {
				width: staticColumnSize(2, 3, $gutter);
			}
		}
	}

	.block--teaser.block--minHeightBg &,
	.gallery &,
	.block--image & {
		@include ifSupportCustomProperties() {
			@include minAspectRatio(var(--heightRatioPerc));
			flex-wrap: wrap;
		}
	}

	&__caption {
		@include font(caption);
		margin-top: $base;
		text-align: right;
		font-size: 18px;
		line-height: 24px;

		.blocks:not(.blocks--nested) > .block--image.block--hSizeFull & {
			@include app-container();
		}

		.block--teaser .block__figure & {
			bottom: 0;
			left: 0;
			padding: $base;
			position: absolute;
			text-align: left;
			width: 100%;
		}
		padding-right: 10px;
		@include media ($smallBreakpoint) {
			padding-right: 0;
		}
	}

	&__img {
		max-width: 100%;
		width: auto; //width: 100%
		height: auto;
		object-fit: contain;

		.image--bg & {
			@include objectFit(cover);
			height: 100%;
			user-select: none;
			width: 100%;
			object-fit: contain; //remove if you want width 100% for all img
		}

		.block--image &,
		.gallery & {
			display: block;
			width: 100%;
		}

		.video &,
		.gallery:not(.gallery--layoutTypeJustified) & {
			@include objectFit(cover);
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			object-fit: contain; //remove if you want width 100% for all img
		}

		.preview & {
			@include objectFit(cover);
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}


	&__imgWrapper {
		max-width: 100%;

		.preview &,
		.gallery:not(.gallery--layoutTypeJustified) & {
			display: block;
			padding-top: math.div(100%, $galleryColumnsImageRatio);
			position: relative;
		}
	}
}

.gray__img {
	filter: grayscale(1);
}
