.block {
	@mixin conditionalContainer() {
		@include app-container();

		.block--hPositionBegin &,
		.block--hPositionEnd & {
			margin-left: 0;
			margin-right: 0;
		}

		.block--group > .block__container > & {
			@include app-undoContainer();

			@include media($columnsBreakpoint) {
				@include app-container();
			}
		}

		.blocks--nested .block:not(.block--delimited) & {
			@include media($columnsBreakpoint) {
				@include app-undoContainer();
			}
		}
	}

	display: flex;
	flex-direction: row;
	position: relative;


	.blocks--nested > & {
		@include media($columnsBreakpoint) {
			@include dynamicColumnsModifiers($layoutGutter);
		}
	}

	&--colorSchemeInverted {
		background-color: $colorBgInverted;
		color: $colorInverted;
	}

	&--colorSchemeAlternative {
		background-color: $colorBgAlternative;
		color: $colorAlternative;
	}

	&--hAlignBegin {
		text-align: left;
	}

	&--hAlignEnd {
		text-align: right;
	}

	&--hAlignCenter {
		text-align: center;
	}

	&--hAlignJustified {
		text-align: justify;
	}


	&--teaser {
		&.block--colorSchemeDefault {
			background-color: $colorBgBase;
		}

		&.block--minHeightFixed {
			height: $teaserMinHeightIeFix; // minor than min-height, to allow align-items center to work on IE11
			min-height: $teaserMinHeight;
		}
	}

	&--slideshow {
		overflow: hidden;
	}

	&__category {
		@include font(category);
		order: 1;
		position: relative;
	}


	&__container {
		display: flex;
		flex-grow: 0;
		flex-shrink: 0;
		flex-direction: column;
		order: 2;
		padding-bottom: $blockVerticalSpacing;
		position: relative;
		width: 100%;
		z-index: 1;

		// delimited variants is used for blocks that have a background or borders
		// in this case we do not want the top space to collapse
		.block--delimited > & {
			padding-top: $blockVerticalSpacing;
		}

		// remove the vertical rithm when they are organized into a grid layout
		.blocks--nested > .block:not(.block--delimited) > & {
			@include media($columnsBreakpoint) {
				padding-bottom: 0;
			}
		}

		.block--delimited + .block:not(.block--delimited) > & {
			padding-top: $blockVerticalSpacing;

			// the vertical spacing needs to be removed because it's already provided by the grid gutters
			.blocks--nested > & {
				@include media($columnsBreakpoint) {
					padding-top: 0;
				}
			}
		}

		// special case: first block of the page
		// we use this specific selector because a generic :first-child could match also in case of nested blocks
		.page > .blocks > .block:first-child > & {
			padding-top: $firstBlockTopSpacing;
		}


		.block--hPositionBegin > & {
			align-items: flex-start;
		}

		.block--hPositionCenter > & {
			align-items: center;
		}

		.block--hPositionEnd > & {
			align-items: flex-end;
		}

		.block--vAlignBegin > & {
			justify-content: flex-start;
		}

		.block--vAlignEnd > & {
			justify-content: flex-end;
		}

		.block--vAlignCenter > & {
			justify-content: center;
		}

		.block--vAlignJustified > & {
			justify-content: flex-start;
			// the content will decide who is taking space and who is not
		}

		.block--minHeightBg > & {
			transform: translateX(-100%);
		}
	}


	&__content {
		@include conditionalContainer();
		display: flex;
		flex-direction: column;
		order: 4;
		position: relative;
		z-index: 1;

		.block--hSizeFull > .block__container > & {
			@include media($columnsBreakpoint) {
				max-width: none;
			}
		}

		.blocks:not(.blocks--nested) > .block--hSizeFull:not(.block--group) & {
			@include app-undoContainer();

			@include media($columnsBreakpoint) {
				@include app-undoContainer();
			}
		}

		.block--vAlignJustified & {
			flex-grow: 1;
		}

		* + & {
			margin-top: $base2;
		}
	}


	&__figure {
		z-index: 0;
		flex-grow: 0;
		flex-shrink: 0;

		&--video {
			overflow: hidden;
		}

		.block--teaser & {
			height: auto;
			opacity: $teaserBgOpacity;
			width: 100%;
			order: 1;
		}

		.block--teaser:not(.block--minHeightBg) & {
			bottom: 0;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
		}
	}


	&__header {
		@include conditionalContainer();
		display: flex;
		flex-direction: column;
		z-index: 1;

		.block--group > .block__container > & {
			@include app-container();
		}
	}


	&__subtitle {
		@include font(subtitle);
		order: 3;
		position: relative;

		* + & {
			margin-top: $base;
		}

		.block--textSizeBigger & {
			@include font(subtitleBigger);
		}
	}


	&__title {
		@include font(title);
		order: 2;
		position: relative;

		.block--textSizeBigger & {
			@include font(titleBigger);
		}
	}
}
@media (max-width: 767px) {
	.block--video > .block__container > .block__content {
		padding: 0;
	}

	.block--bild > .block__container > .block__content {
		padding: 0;
	}
}
