
.header {
	//STICKY MENU
	//position: sticky;
	//top: 0;
	//transition: 0.3s all;
	//background-color: #ffffff00;
	//z-index: 99;
	//height: 80px;

	//&_active {
	//	background-color: #ffffffe3;
	//	transition: 0.3s all;
	//}

	&__navbar {
		padding-top: 26px;

		.container {
			display: flex;
			justify-content: flex-end;
		}
	}

	&__languages {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-right: 20px;
	}

	&__svg svg {
		margin-bottom: 5px;
	}

	&__languageMenu {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	&__language {
		a,
		span {
			text-decoration: none;
			color: black;
			cursor: pointer;
			align-self: flex-end;
			line-height: 1.6rem;
			font-size: calcFluidFontSize(18, 21, 360, 1440);
			letter-spacing: 2px;
			transition: 0.3s all;
			margin-right: 20px;
			@include media ($largeBreakpoint) {
				font-size: 21px;
			}

			&:hover {
				color: $colorActiveLink;
			}
		}

		&--menuLabel {
			margin-right: 0;
			span {
				font-style: italic;
			}
		}

		&_italic {
			font-style: italic;
		}

		&_active {
			a {
				color: $colorActiveLink;
			}
			svg * {
				fill:  $colorActiveLink !important; // override svg fill
			}
		}
	}

	&__wrapper {
		width: 0;
		min-height: 704px;
		position: absolute;
		top: 0;
		right: 0;
		overflow: hidden;
		z-index: -1;

		&_active {
			width: 464px;
		}
	}

	&__menu {
		background-color: $colorMenu;
		width: 100%;
		min-height: 704px;
		overflow-y: auto;
		transition: 0.3s all;
		padding-top: 34px;
		padding-left: 32px;
		padding-right: 34.96px;
		padding-bottom: 90px;
		transform: translateX(100%);

		&_active {
			transform: translateX(0%);
		}

		&-close {
			position: absolute;
			left: 33px;
			bottom: 24px;
			width: 27px;
			height: 27px;
			cursor: pointer;

			&::before,
			&::after {
				position: absolute;
				left: 15px;
				content: ' ';
				height: 33px;
				width: 2px;
				background-color: $colorGreen;
			}

			&::before {
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}
		}
	}

	&__sm-logo {
		display: block;
		width: 100%;
		text-align: right;
		color: $colorGreen;
		font-size: 30px;
	}
}
@media (max-width: 576px) {
	.header {
		&__wrapper {
			width: 0;

			&_active {
				width: 100%;
			}
		}
	}
}
@media (max-width: 376px) {
	.menu {
		&__item {
			a {
				display: block;
				width: 100%;
				font-size: 7vw;

				&:hover {
					color: #000;
				}
			}
		}
	}
}
